import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ES1 from "./components/es1";
import ES2 from "./components/es2";
import ESSCM from "./components/esScm";
import ESBEP from "./components/esBep";
import ESBOM from "./components/esBom";
import ESIND from "./components/exInd";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ES1 />} />
        <Route path="/es-k" element={<ES2 />} />
        <Route path="/es-scm" element={<ESSCM />} />
        <Route path="/es-bep" element={<ESBEP />} />
        <Route path="/es-bom" element={<ESBOM />} />
        <Route path="/es-ind" element={<ESIND />} />
      </Routes>
    </Router>
  );
}

export default App;
