import axios from "axios";
import { useState, useEffect } from "react";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Report } from "notiflix/build/notiflix-report-aio";

function ES1() {
  const states = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];
  const [name, setName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [utmMedium, setUtmMedium] = useState("");
  const [utmSource, setUtmSource] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      name &&
      name.trim() !== "" &&
      phoneNumber &&
      phoneNumber.trim() !== "" &&
      phoneNumber.length === 10 &&
      email &&
      email.trim() !== "" &&
      state &&
      state.trim() !== "" &&
      city &&
      city.trim() !== ""
    ) {
      Loading.standard("Loading...");
      const url = `https://campaign.carzonwheel.com/v1/hdfcBank/save-lead?name=${name}&mobile=${phoneNumber}&email=${email}&state=${state}&city=${city}&utm_medium=${utmMedium}&utm_source=${utmSource}&utm_campaign=${utmCampaign}`;
      const res = await axios.post(url);
      if (res.status == 200) {
        Loading.remove();
        setName(null);
        setPhoneNumber(null);
        setEmail(null);
        setState(null);
        setCity(null);
        Report.success("Success", `${res.data.message}`, "Okay");
        console.log("Success");
      } else {
        Report.failure("Error", `Something went wrong`, "Okay");
      }
    } else {
      Report.failure("Error", `Please fill with form properly`, "Okay");
    }
  };

  const handlePhoneNumberChange = (v) => {
    if (v !== "") {
      if (/^\d+$/.test(v) && !/^\d{11}$/.test(v)) {
        setPhoneNumber(v);
      }
    } else {
      setPhoneNumber("");
    }
  };

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    setName(params.name);
    if (/^\d+$/.test(params.phone) && !/^\d{11}$/.test(params.phone)) {
      setPhoneNumber(params.phone);
    }
    setEmail(params.email);
    setState(params.state);
    setCity(params.city);
    setUtmMedium(params.utm_medium);
    setUtmSource(params.utm_source);
    setUtmCampaign(params.utm_campaign);
  }, []);
  return (
    <>
      <header className="w-full bg-[#AC42C0] p-5 flex items-center justify-center shadow-md">
        <img className="w-40" src="/logo_one.png" alt="logo" />
      </header>
      <div className="form_container">
        <div className="block md:flex rounded-md">
          <div className="sec-1 flex-1 bg-[#AC42C0]">
            <img
              src="/img/img_1.jpeg"
              className="rounded-md w-full h-[89vh]"
              alt="banner_img"
            />
          </div>
          <div className="sec-2 flex-1 bg-gray-100">
            <div className="flex justify-center items-center h-full">
              <form
                className="*:my-2.5 border bg-white p-5 rounded-md shadow-2xl"
                onSubmit={(e) => handleSubmit(e)}
              >
                <div>
                  <label className="mb-2 font-semibold" htmlFor="name">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    required
                    onChange={(e) => setName(e.target.value)}
                    defaultValue={name}
                    className="border outline-none p-2 w-full rounded-md"
                  />
                </div>
                <div>
                  <label className="mb-2 font-semibold" htmlFor="phone">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    onChange={(e) => handlePhoneNumberChange(e.target.value)}
                    value={phoneNumber}
                    className="border outline-none p-2 w-full rounded-md"
                  />
                </div>
                <div>
                  <label className="mb-2 font-semibold" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    defaultValue={email}
                    className="border outline-none p-2 w-full rounded-md"
                  />
                </div>
                <div>
                  <label className="mb-2 font-semibold" htmlFor="state">
                    State
                  </label>
                  <select
                    className="border outline-none p-2 w-full rounded-md"
                    name="state"
                    required
                    onChange={(e) => setState(e.target.value)}
                    id="state"
                  >
                    <option value="">-- Select State --</option>
                    {states.map((_state) => (
                      <>
                        {state === _state ? (
                          <option selected value={_state}>
                            {_state}
                          </option>
                        ) : (
                          <option value={_state}>{_state}</option>
                        )}
                      </>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="mb-2 font-semibold" htmlFor="city">
                    City
                  </label>
                  <input
                    type="text"
                    id="city"
                    required
                    defaultValue={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="border outline-none p-2 w-full rounded-md"
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="gradient_bg w-full text-white p-2 rounded-md mt-5"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ES1;
